import { HomeWorkOutlined } from "@material-ui/icons";
import { Conversation } from "../types/api";
import Pill from "./Pill";

export default function ConversationContext(props: {
  conversation: Conversation;
}) {
  const { conversation } = props;

  return (
    <div>
      {conversation.admin_buildings.length > 0 ? (
        <div className="text-sm text-current text-gray-500 font-semibold">
          <HomeWorkOutlined style={{ fontSize: 16, marginTop: -2 }} /> Manager
        </div>
      ) : null}
      {conversation.unit ? (
        <div className="text-sm text-current text-gray-500 font-normal">
          <span className="font-semibold">
            {conversation.unit.building.name}
          </span>{" "}
          • Unit {conversation.unit.number}
        </div>
      ) : null}
    </div>
  );
}
