export default function getSegmentAnalytics() {
  return process.env.NEXT_PUBLIC_SEGMENT_API_WRITE_KEY &&
    typeof window !== "undefined"
    ? (window as any).analytics
    : null;
}

export function segmentTrack(eventName: string, props: object = {}) {
  if (!getSegmentAnalytics()) {
    return;
  }

  getSegmentAnalytics().track(eventName, { ...props, source: "Portal" });
}
