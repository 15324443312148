import axios from "axios";
import { Session } from "next-auth";
import { getSession } from "next-auth/client";

export const apiRoot = process.env.NEXT_PUBLIC_PORTAL_API_ROOT;

export const authHeaders = (accessToken: string) => {
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export const deleteAuthHeaders = () => {
  delete axios.defaults.headers.common["Authorization"];
};

export const fetcher = async (url: string, session: Session | null) => {
  let token: string;

  // session was explicitly passed through (typically run in server context)
  if (session) {
    token = (session.accessToken as string) || "";
  }
  // pull up to date session from server (refreshes if necessary)
  else {
    const refreshSession = await getSession();
    token = (refreshSession?.accessToken as string) || "";
  }

  // set the default axios headers (so that subsequent non-fetcher calls are authorized)
  if (token && token !== "") {
    axios.defaults.headers.common = authHeaders(token);
  } else {
    deleteAuthHeaders();
  }

  return axios.get(url).then((res) => {
    return res.data;
  });
};

const token = `${apiRoot}/token/`;
const users = `${apiRoot}/users/`;
const tenants = `${apiRoot}/tenants/`;
const tenantsInvite = `${tenants}invite/`;
const tenantsFeedbackRequest = `${tenants}request-feedback/`;
const doorAccesses = `${apiRoot}/door_accesses/`;
const buildings = `${apiRoot}/buildings/`;
const eventPosts = `${apiRoot}/event_posts/`;
const communityPosts = `${apiRoot}/community_posts/`;
const buildingPostReports = `${apiRoot}/building_post_reports/`;
const uploads = `${apiRoot}/uploads/`;
const conversations = `${apiRoot}/conversations/`;
const integrationBrivo = `${apiRoot}/integration/brivo/`;
const integrationSkywalk = `${apiRoot}/integration/skywalk/`;
const surveys = `${apiRoot}/surveys/`;
const surveyTemplates = `${apiRoot}/survey-templates/`;

export const apiUrls = {
  tokenPassword: `${token}password/`,
  tokenRefresh: `${token}refresh/`,
  tokenVerify: `${token}verify/`,
  usersMe: `${users}me/?omit=door_access`,
  usersVerify: `${users}verify/`,
  uploadS3: `${uploads}s3/`,
  tenants,
  tenantsInvite,
  tenantsFeedbackRequest,
  tenant: (id: string) => `${tenants}${id}/`,
  tenantMoveOut: (id: string) => `${tenants}${id}/move-out/`,
  doorAccess: (id: string) => `${doorAccesses}${id}/`,
  buildings,
  buildingsFloors: `${buildings}floors/`,
  building: (id: string) => `${buildings}${id}/`,
  buildingUnits: (id: string) => `${apiUrls.building(id)}units/`,
  buildingOccupants: (id: string) => `${buildings}${id}/occupants/`,
  buildingDoors: (id: string) => `${buildings}${id}/doors/`,
  buildingDoorsUpdate: (buildingId: string, doorId: string) =>
    `${buildings}${buildingId}/doors/${doorId}/`,
  buildingDoorUnlock: (buildingId: string, doorId: string) =>
    `${buildings}${buildingId}/doors/${doorId}/unlock/`,
  eventPosts,
  eventPost: (id: string) => `${eventPosts}${id}/`,
  communityPosts,
  buildingPostReports,
  approveBuildingPost: (id: string) => `${buildingPostReports}${id}/approve/`,
  removeBuildingPost: (id: string) => `${buildingPostReports}${id}/remove/`,
  communityPost: (id: string) => `${communityPosts}${id}/`,
  communityPostComments: (communityPostId: string) =>
    `${communityPosts}${communityPostId}/comments/`,
  communityPostComment: (communityPostId: string, commentId: string) =>
    `${communityPosts}${communityPostId}/comments/${commentId}/`,
  eventPostComments: (eventPostId: string) =>
    `${eventPosts}${eventPostId}/comments/`,
  eventPostComment: (eventPostId: string, commentId: string) =>
    `${eventPosts}${eventPostId}/comments/${commentId}/`,
  conversations,
  conversationsBulk: `${conversations}bulk/`,
  conversation: (id: string) => `${conversations}${id}/`,
  conversationMessages: (conversationId: string) =>
    `${conversations}${conversationId}/messages/`,
  conversationMessage: (conversationId: string, messageId: string) =>
    `${conversations}${conversationId}/messages/${messageId}/`,
  integrationOpenpath: `${apiRoot}/integration/openpath/`,
  integrationOpenpathUnset: `${apiRoot}/integration/openpath/unset/`,
  integrationBrivo,
  integrationBrivoOauthRedirect: `${integrationBrivo}oauth_redirect/`,
  integrationBrivoUnset: `${integrationBrivo}unset/`,
  integrationSkywalk,
  integrationSkywalkUnset: `${integrationSkywalk}unset/`,
  surveys,
  survey: (id: string) => `${surveys}${id}/`,
  duplicateSurvey: (id: string) => `${surveys}${id}/duplicate/`,
  closeSurvey: (id: string) => `${surveys}${id}/close/`,
  surveysStats: `${surveys}stats/`,
  surveyTemplates,
  surveyTemplate: (id: string) => `${surveyTemplates}${id}/`,
  duplicateSurveyTemplate: (id: string) => `${surveyTemplates}${id}/duplicate/`,
};
