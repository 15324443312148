import { Comment } from "../types/api";

// regular expression for tagged / mentioned users
const tagged_re = /@\[([^[]*)]\((?:[^)(]+|\((?:[^)(]+|\([^)(]*\))*\))*\)/gm;

// regular expression for URL's
const url_re =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export function CommentWithMentions(props: { comment: Comment }) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: props.comment.text
          .replace(
            tagged_re,
            "<span class='text-blue-300 font-bold'>@$1</span>"
          )
          .replace(
            url_re,
            "<a href='$1' target='_blank' class='font-bold underline cursor-pointer'>$1</a>"
          ),
      }}
      className={"whitespace-pre-line"}
    />
  );
}

export function TitleWithMentions(props: { content: string }) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: props.content
          .replace(tagged_re, "<span class='text-blue-300'>@$1</span>")
          .replace(
            url_re,
            "<a href='$1' target='_blank' class='font-bold underline cursor-pointer'>$1</a>"
          ),
      }}
    />
  );
}
