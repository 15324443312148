import React from "react";
import Router from "next/router";
import getSegmentAnalytics from "../lib/segment";

// Track client-side page views with Segment
Router.events.on("routeChangeComplete", (url) => {
  if (getSegmentAnalytics()) {
    getSegmentAnalytics().page(url, {
      source: "Portal",
    });
  }
});

const TrackedPage = (props: { children: React.ReactNode }) => (
  <>{props.children}</>
);

export default TrackedPage;
