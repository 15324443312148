import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { LDUser } from "launchdarkly-js-client-sdk";
import React from "react";
import { Provider } from "next-auth/client";
import { AppProps } from "next/app";
import { ToastProvider } from "react-toast-notifications";
import TrackedPage from "../components/TrackedPage";
import "../styles/base.css";
import "../styles/components.css";
import "../styles/utilities.css";
import "../styles/lib.css";
import { useEffect, useState } from "react";
import { ModalContext } from "../hooks/useModals";
import { useUser } from "../hooks/useUser";
import { Conversation } from "../types/api";
import ConversationPane from "../components/ConversationPane";

function MyApp({ Component, pageProps }: AppProps) {
  const [selectedConversation, setSelectedConversation] = useState<
    Conversation | undefined
  >(undefined);

  const [LDProvider, setLDProvider] = useState<null | React.FunctionComponent>(
    null
  );
  const user = useUser();
  useEffect(() => {
    (async () => {
      if (process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID) {
        // initialize the LaunchDarkly "User" as anonymous
        var launchDarklyUser: LDUser = {
          key: "anonymous",
          anonymous: true,
        };
        // once the Portal user has logged in update the LaunchDarkly "User"
        if (user) {
          launchDarklyUser = {
            key: user.id.toString(),
            anonymous: false,
            firstName:
              (user.profile.preferred_first_name || user.first_name) ??
              undefined,
            lastName:
              (user.profile.preferred_last_name || user.last_name) ?? undefined,
            email: user.email ?? undefined,
            custom: {
              organizationName: user.organization.name,
            },
          };
        }

        const provider = await asyncWithLDProvider({
          clientSideID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID,
          user: launchDarklyUser,
          options: {
            streaming: true,
          },
        });
        setLDProvider(() => provider);
      }
    })();
  }, [user]);

  return (
    <Provider session={pageProps.session}>
      {LDProvider && (
        <LDProvider>
          <ToastProvider autoDismiss autoDismissTimeout={6000}>
            <TrackedPage>
              <ModalContext.Provider
                value={{
                  openConversationPaneWithConversation: (
                    conversation: Conversation
                  ) => {
                    setSelectedConversation(conversation);
                  },
                  closeConversationPane: () =>
                    setSelectedConversation(undefined),
                }}
              >
                {/* Core page */}
                <Component {...pageProps} />

                {/* Persistent UI */}
                <ConversationPane
                  conversation={selectedConversation}
                  onClose={() => {
                    setSelectedConversation(undefined);
                  }}
                />
              </ModalContext.Provider>
            </TrackedPage>
          </ToastProvider>
        </LDProvider>
      )}
    </Provider>
  );
}

export default MyApp;
