import useSWR from "swr";
import { User } from "../types/api";
import { apiUrls, fetcher } from "../utils/api";

export function useUser() {
  const { data: user } = useSWR<User>(apiUrls.usersMe, fetcher, {
    revalidateOnFocus: false,
  });
  return user;
}
