import { createContext, useContext } from "react";
import { Conversation } from "../types/api";

export const ModalContext = createContext({
  openConversationPaneWithConversation: (conversation: Conversation) => {},
  closeConversationPane: () => {},
});

export function useModals() {
  return useContext(ModalContext);
}
