import { yupResolver } from "@hookform/resolvers/yup";
import { Close, SendRounded } from "@material-ui/icons";
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import { useToasts } from "react-toast-notifications";
import useSWR, { useSWRInfinite, mutate as globalMutate } from "swr";
import * as yup from "yup";
import { useUser } from "../hooks/useUser";
import { segmentTrack } from "../lib/segment";
import { Comment, Conversation, PaginatedMessages } from "../types/api";
import { apiUrls, fetcher } from "../utils/api";
import { classNames } from "../utils/classNames";
import { getUserFullName } from "../utils/users";
import { CommentWithMentions } from "./Comment";
import ConversationContext from "./ConversationContext";

type MessageGroup = {
  authorName: string;
  authorId: number;
  messages: Comment[];
};

const schema = yup.object({
  text: yup.string().required(),
});
type MessageInputs = yup.InferType<typeof schema>;

export default function ConversationPane(props: {
  conversation?: Conversation | undefined;
  onClose: (shouldRevalidate: boolean) => void;
}) {
  const { conversation, onClose } = props;
  const { addToast } = useToasts();
  const [messageGroups, setMessageGroups] = useState<MessageGroup[]>([]);
  const { handleSubmit, register, reset } = useForm<MessageInputs>({
    resolver: yupResolver(schema),
  });
  const user = useUser();

  const PAGE_SIZE: number = 10;
  const getKey = (pageIndex: number, previousPageData) => {
    if (previousPageData && !previousPageData.next) return null;

    if (pageIndex === 0) {
      if (conversation) {
        const url = apiUrls.conversationMessages(conversation.id.toString());
        return url;
      } else {
        return null;
      }
    }

    return previousPageData.next;
  };
  const { data, mutate, size, setSize } = useSWRInfinite(getKey, fetcher, {
    refreshInterval: 10000,
  });

  // transform messages returned from API into MessageGroups,
  // grouping consequitive authors into their author keys
  // for grouping messages by author and only displaying the
  // author once
  useEffect(() => {
    if (!data || !(data.length > 0)) {
      return;
    }

    var allMessages: any[] = [];
    data.map((pagedData: PaginatedMessages) => {
      if (pagedData.results) {
        allMessages = allMessages.concat(pagedData.results);
      }
    });

    setMessageGroups(
      allMessages.reduce<MessageGroup[]>((groups, message) => {
        if (!message.created_by) {
          return groups;
        }
        if (
          groups.length === 0 ||
          groups[groups.length - 1].authorId !== message.created_by.id
        ) {
          return [
            ...groups,
            {
              authorId: message.created_by.id,
              authorName: getUserFullName(message.created_by),
              messages: [message],
            },
          ];
        } else {
          groups[groups.length - 1].messages.push(message);
          return groups;
        }
      }, [])
    );
  }, [data]);

  const messageFeedListRef = useRef(null);
  const handleScrollToTop = () => {
    if (messageFeedListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        messageFeedListRef.current;
      // use inverse of scrollTop because we're going from the bottom to the top
      if (-scrollTop + clientHeight === scrollHeight) {
        setSize(size + 1);
      }
    }
  };

  // send message form submission
  const onSubmit = async (data: MessageInputs) => {
    if (!conversation) {
      return;
    }
    try {
      await axios.post<Comment>(
        apiUrls.conversationMessages(conversation.id.toString()),
        data
      );
      reset();
      mutate();
      globalMutate(apiUrls.conversations);
      segmentTrack("DM sent", {
        recipientId: conversation.recipients[0].id,
        recipientName: getUserFullName(conversation.recipients[0]),
      });
    } catch (err) {
      addToast(
        "Well, shoot. Something went wrong. Try again or contact support!",
        { appearance: "error" }
      );
    }
  };

  const recipient = conversation?.recipients[0];

  return (
    <div
      className={classNames(
        conversation !== undefined ? "fixed" : "hidden",
        "top-16 bottom-0 left-0 right-0 md:h-96 md:w-96 md:inset-auto md:bottom-0 md:right-10 flex flex-col bg-white border border-gray-100 rounded-t-lg"
      )}
    >
      {/* Pane header */}
      <div className="flex items-center py-2 px-4 border-b border-gray-100 bg-gray-50">
        <div className="mr-auto">
          <div className="font-semibold mb-1">
            {recipient ? getUserFullName(recipient) : "..."}
          </div>
          {conversation ? (
            <ConversationContext conversation={conversation} />
          ) : null}
        </div>
        <Close
          className="p-1 cursor-pointer rounded-md hover:bg-gray-100"
          onClick={() => onClose(false)}
        />
      </div>

      {/* Messages feed  */}
      <div
        className="flex flex-col flex-col-reverse h-full overflow-y-scroll p-4 no-scrollbar"
        ref={messageFeedListRef}
        onScroll={handleScrollToTop}
      >
        {user &&
          messageGroups.map((g) => {
            return g.messages.map((m, i) => {
              if (!m.created_by) {
                return;
              }
              const isCurrentUser = m.created_by.id === user.id;
              const isLastOfGroup = i === g.messages.length - 1;

              return (
                <Fragment key={m.id}>
                  <div
                    className={classNames(
                      isCurrentUser ? "ml-auto" : "mr-auto",
                      "break-words w-full max-w-max"
                    )}
                  >
                    <div
                      className={classNames(
                        isCurrentUser ? "bg-blue-500" : "bg-gray-500",
                        "px-2 py-1 mb-1 rounded-xl text-white text-sm"
                      )}
                    >
                      <CommentWithMentions comment={m} />
                    </div>
                  </div>
                  {isLastOfGroup ? (
                    <div
                      className={classNames(
                        isCurrentUser ? "ml-auto" : "mr-auto",
                        "my-2"
                      )}
                    >
                      {getUserFullName(m.created_by)}
                    </div>
                  ) : null}
                </Fragment>
              );
            });
          })}
      </div>

      {/* Pane form */}
      <div className="p-4 bg-gray-50 border-t border-gray-100">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="relative flex items-center">
            <TextareaAutosize
              minRows={1}
              maxRows={5}
              id="text"
              {...register("text")}
              className="field pr-8 rounded-lg border-gray-100 resize-none"
            />
            <div
              className="absolute right-1 rounded-lg hover:bg-gray-100 cursor-pointer p-1"
              onClick={handleSubmit(onSubmit)}
            >
              <SendRounded className="text-gray-700" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
